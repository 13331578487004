import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "startDate", "endDate", "dueDate", "referenceType", "organizationOption", "workmateOption", "invoiceDate" ]
  static values = { url: String, projectUrl: String }

  async fetchProject(event) {
    event.preventDefault();
    const url = new URL(this.projectUrlValue);
    url.search = new URLSearchParams(this.fetchProjectParams);
    const request = new FetchRequest('get', url.toString(), { responseKind: 'json' })
    const response = await request.perform();
    const workMateSelect = this.workmateOptionTarget
    workMateSelect.options.length = 0
    if (response.ok) {
      const json = await response.json
      workMateSelect.options.add(new Option(" ", null))
      Array.from(json).forEach(function(item) {
        workMateSelect.options.add(new Option(item.contractor.full_name, item.contractor.id))
      });
    }
  }

  async generateItems(event) {
    event.preventDefault();
    let elem = event.target;
    elem.classList.add('disabled');
    elem.innerHTML = "<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span> Building..."
    const url = new URL(this.urlValue);
    url.search = new URLSearchParams(this.buildItemsParams);
    const request = new FetchRequest('get', url.toString(), { responseKind: "turbo-stream"})
    const response = await request.perform();
    if (!response.ok) {
      const json = await response.json
      elem.classList.remove('disabled');
      elem.innerHTML = 'Build Items';
      alert(json.error)
    }
  }

  get fetchProjectParams() {
    const organizationId = this.organizationOptionTarget.value
    return  {
      status: 'active',
      organization_id: organizationId
    }
  }

  get buildItemsParams() {
    return  {
      reference_type: this.referenceTypeTarget.value,
      start_date: this.startDateTarget.value,
      end_date: this.endDateTarget.value,
      due_date: this.dueDateTarget.value,
      organization_id: this.organizationOptionTarget.value,
      contractor_id: this.workmateOptionTarget.value,
      invoice_date: this.invoiceDateTarget.value
    }
  }
}
