import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "netTotal", "taxTotal", "subTotal" ]
  static values = { gstApplied: String }

  computeRowTotal(event) {
    let elem = event.target;
    let tr = elem.closest("tr")
    let fldRate = tr.getElementsByClassName('fldRate')[0]
    let fldQty = tr.getElementsByClassName('fldQty')[0]
    let rowTotal = tr.getElementsByClassName('rowTotal')[0]
    this.getRowTotal(fldRate, fldQty, rowTotal)
  }

  computeNetTotal() {
    let total = 0;
    Array.from(document.querySelectorAll('.rowTotal')).forEach(function(item) {
      let rowTotal = parseFloat(item.textContent)
      total += (isNaN(rowTotal) ? 0 : rowTotal)
    });

    if (this.gstAppliedValue === 'true') {
      let totalTaxamount = total * 0.1
      this.subTotalTarget.textContent = total.toFixed(2)
      this.taxTotalTarget.textContent = totalTaxamount.toFixed(2)
      this.netTotalTarget.textContent = (total + totalTaxamount).toFixed(2)
    } else {
      this.netTotalTarget.textContent = total.toFixed(2)
    }
  }

  getRowTotal(fldRate, fldQty, rowTotal) {
    const rateVal = ((isNaN(fldRate.value) || fldRate.value == '') ? 0 : parseFloat(fldRate.value))
    const qtyVal = ((isNaN(fldQty.value)  || fldQty.value == '') ? 0 : parseFloat(fldQty.value))
    const rowTotalVal = (rateVal * qtyVal).toFixed(2)

    rowTotal.textContent = rowTotalVal
    this.computeNetTotal()
  }
}
