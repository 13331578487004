function closeModal() {
  const modal = document.getElementById('appModal')
  modal.remove()
  Array.from(document.querySelectorAll('.modal-backdrop')).forEach(function(elem) {
    elem.remove();
  });
  const body = document.getElementsByTagName('body')[0]
  body.classList.remove('modal-open')
  body.removeAttribute('style')
}

export { closeModal }