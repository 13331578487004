import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  remove(e) {
    super.remove(e)
    const wrapper = e.target.closest(this.wrapperSelectorValue);
    const totalCol = wrapper.getElementsByClassName('rowTotal')
    if (totalCol.length > 0) {
      totalCol[0].classList.remove('rowTotal')
    }
    const table = document.getElementById('nestedFieldsTable')
    if (table) {
      const calculateFieldsController = this.application.getControllerForElementAndIdentifier(table, 'calculate-fields')
      calculateFieldsController.computeNetTotal()
    }
  }
}
