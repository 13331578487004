import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "category", "hint", "validUntil" ]
  static values = { currencyCode: String }

  handleBehavior(){
    const category = this.categoryTarget.value
    if (category == 'one_off_peso' || category == 'recurring_peso') {
      this.hintTarget.textContent = 'in PHP'
    } else {
      this.hintTarget.textContent = `in ${this.currencyCodeValue}`
    }

    if (category == 'one_off_peso' || category == 'one_off_client_currency') {
      this.validUntilTarget.classList.add('d-none')
    } else {
      this.validUntilTarget.classList.remove('d-none')
    }

  }
}
