import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "payrollReportForm" ]

  exportPaySlipsToExcel(event) {
    event.preventDefault();
    const elem = event.target;
    elem.classList.add('disabled');
    elem.innerHTML = "<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span> Exporting..."
    const url = new URL(elem.getAttribute('href'));
    url.search = new URLSearchParams(this.payrollReportParams);
    window.location.href = url.toString();
    elem.classList.remove('disabled');
    elem.innerHTML = 'Export as XLSX';
  }

  get payrollReportParams() {
    const formData = new FormData(this.payrollReportFormTarget);
    return {
      no: formData.get('no'),
      q: formData.get('q'),
      pay_date: formData.get('pay_date'),
      include_archived: formData.get('include_archived'),
    }
  }
}
