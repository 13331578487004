import { Application } from "stimulus"
import NestedForm from "stimulus-rails-nested-form"
import Flatpickr from 'stimulus-flatpickr'

require("flatpickr/dist/flatpickr.css")

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

// Import and register all your controllers within this directory and all subdirectories
// Controller files must be named *_controller.js or *_controller.ts
import { definitionsFromContext } from "stimulus/webpack-helpers"
const context = require.context("controllers", true, /_controller\.js$/)

application.register("nested-form", NestedForm)
application.register('flatpickr', Flatpickr)
application.load(definitionsFromContext(context))
