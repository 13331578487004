import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "dateFrom", "dateTo", "payDate" ]
  static values = { url: String }

  async adjust(event) {
    event.preventDefault();
    const url = new URL(this.urlValue);
    url.search = new URLSearchParams(this.dateParams);
    const request = new FetchRequest('get', url.toString(), { responseKind: "turbo-stream"})
    await request.perform();
  }

  get dateParams() {
    return  {
      date_from: this.dateFromTarget.value,
      date_to: this.dateToTarget.value,
      pay_date: this.payDateTarget.value
    }
  }
}
