import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', (event) => {
    const adminUserId = document.querySelector('header').dataset.adminUserId;

    if (adminUserId) {
        consumer.subscriptions.create({ channel: "NotificationsChannel", admin_user_id: adminUserId }, {
            connected() {
            },
            received(data) {
                updateNotificationsUI(data);
            }
        });
    }
});




function updateNotificationsUI(data) {

    // Add the new notification to the dropdown menu
    let dropdownMenu = document.querySelector('.notification-dropdown');
    let newNotification = document.createElement('div');
    newNotification.classList.add('dropdown-item', 'd-flex', 'justify-content-between', 'align-items-center', 'gap-2');
    newNotification.id = 'notification-' + data.id;

    // Create notification message
    let message = document.createElement('span');
    message.classList.add('notif-message');
    message.innerText = data.message;

    // Create timestamp
    // let timestampDiv = document.createElement('div');
    // let timestamp = document.createElement('small');
    // timestamp.classList.add('text-muted');
    // timestamp.innerText = 'Just now';  // You can use a time library like moment.js to parse data.created_at to a human readable time
    // timestampDiv.appendChild(timestamp);

    // Create close button
    let closeButton = document.createElement('a');
    closeButton.href = '/notifications/' + data.id + '/mark_as_read'; // URL for the mark_as_read action
    closeButton.dataset.remote = 'true';
    closeButton.dataset.method = 'put';
    closeButton.innerHTML = '<span class="notification-close-icon fa fa-times"></span>';

    // Append the message, timestamp and close button to the new notification
    newNotification.appendChild(message);
    // newNotification.appendChild(timestampDiv);
    newNotification.appendChild(closeButton);

    // Add the new notification to the dropdown
    dropdownMenu.insertBefore(newNotification, dropdownMenu.firstChild);

    // Update the count badge
    let badge = document.querySelector('.badge');
    let currentCount = parseInt(badge.innerText);
    badge.innerText = currentCount + 1;
}

