import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "timeString" ]

  connect() {
    const dateTime = this.timeStringTarget.textContent
    const timeZone = moment.tz.guess();
    const zoneAbbr = moment.tz(timeZone).zoneAbbr();
    const thisTime = moment.tz(dateTime, timeZone).format('YYYY-MM-DD HH:mm:ss');
    this.timeStringTarget.textContent = thisTime + ' ' + zoneAbbr;
  }
}
