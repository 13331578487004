// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '@popperjs/core'
import 'bootstrap/dist/js/bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all'
import "../stylesheets/application"
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'controllers'
import moment from 'moment-timezone'
import Trix from "trix"

window.moment = moment
window.bootstrap = require('bootstrap/dist/js/bootstrap');
window.Turbo = Turbo
Rails.start()
ActiveStorage.start()
