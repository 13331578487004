import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
    if (modal.children.length > 0) {
      this.myModal.show()
    }
  }

  closeModal() {
    this.modalTarget.remove();
    Array.from(document.querySelectorAll('.modal-backdrop')).forEach(function(elem) {
      elem.remove();
    });
    const body = document.getElementsByTagName('body')[0]
    body.classList.remove('modal-open')
    body.removeAttribute('style')
  }

  get myModal(){
    return new bootstrap.Modal(this.modalTarget, { keyboard: true })
  }
}
