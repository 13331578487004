import { Controller } from "stimulus"
import { closeModal } from "../utils";
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["fldDateFrom", "fldDateTo", "datesForm", 'manualTimeEntryForm']
  static values = { syncUrl: String, clearUrl: String, summaryUrl: String }

  async filter(event) {
    event.preventDefault();
    const url = new URL(this.datesFormTarget.action);
    url.search = new URLSearchParams(this.dateParams);
    const request = new FetchRequest('get', url.toString(), { responseKind: "turbo-stream"})
    await request.perform();
  }

  async sync(event) {
    event.preventDefault();
    let elem = event.target;
    elem.classList.add('disabled');
    elem.innerHTML = "<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span> Syncing..."
    let url = this.syncUrlValue;
    const request = new FetchRequest('post', url, { body: this.dateParams, responseKind: "turbo-stream"})
    const response = await request.perform()
    if (response.ok) {
      elem.classList.remove('disabled');
      elem.innerHTML = 'Sync Worksnaps Hours';
    }
  }

  async clear(event) {
    event.preventDefault()
    let url = this.clearUrlValue;
    const request = new FetchRequest('delete', url, { body: this.dateParams, responseKind: "turbo-stream" })
    const response = await request.perform()
    if (response.ok) {
      event.target.removeAttribute('disabled');
      event.target.innerHTML = 'Clear Worksnaps Hours';
    }
  }

  async viewSummary(event) {
    event.preventDefault();
    const anchor = event.target.parentNode.closest('a');
    const url = new URL(this.summaryUrlValue);
    let params = {
      date_from: this.fldDateFromTarget.value,
      date_to: this.fldDateToTarget.value,
      project_id: anchor.dataset.projectId
    }
    url.search = new URLSearchParams(params);
    const request = new FetchRequest('get', url.toString(), { responseKind: "turbo-stream"})
    await request.perform();
  }

  async launchAdjustTimeEntryModal(event) {
    event.preventDefault();
    const anchor = event.target;
    const url = anchor.getAttribute('href');
    const request = new FetchRequest('get', url, { responseKind: "turbo-stream"})
    await request.perform();
  }

  async adjustTimeEntry(event) {
    event.preventDefault();
    const elem = event.target;
    const url = this.manualTimeEntryFormTarget.getAttribute('action');
    const method = url.includes('projects') ? 'post' : 'patch'
    elem.classList.add('disabled');
    elem.innerHTML = "<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span> Saving..."
    const request = new FetchRequest(method, url, { body: this.manualTimeEntryFormParams, responseKind: "turbo-stream"})
    const response = await request.perform()
    if (response.ok) {
      closeModal()
    } else {
      response.renderTurboStream()
    }
  }

  addPaidDayOffReason(event) {
    const chkBox = event.target;
    const reasonFld = document.getElementById('manual_time_entry_reason');
    const date = event.target.dataset.date
    if (chkBox.checked) {
      reasonFld.value = `Paid day off for ${date}`
    }else{
      reasonFld.value = ''
    }
  }

  get dateParams() {
    let formData = new FormData(this.datesFormTarget);
    return  {
      date_from: formData.get('date_from'),
      date_to: formData.get('date_to'),
    }
  }

  get manualTimeEntryFormParams() {
    const chkBox = document.getElementById('manual_time_entry_paid_day_off')
    let paidDayOff = 0
    if (chkBox.checked) { paidDayOff = 1 }
    const formData = new FormData(this.manualTimeEntryFormTarget);
    const pageOrigin = formData.get('page_origin')
    let dateParams = {}
    if (pageOrigin === 'details') {
      dateParams = {
        date_from: document.getElementById('date_from').value,
        date_to: document.getElementById('date_to').value,
      }
    }
    return {
      manual_time_entry: {
        date_applied: formData.get('manual_time_entry[date_applied]'),
        hours: formData.get('manual_time_entry[hours]'),
        paid_day_off: paidDayOff,
        reason: formData.get('manual_time_entry[reason]'),
      },
      page_origin: pageOrigin,
      ...dateParams
    }
  }
}