import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "filterScope",
    "filterClassification",
    "includedCount",
    "excludedCount",
    "includedOptions",
    "excludedOptions",
    "eligibleForm"
  ]

  applyScope() {
    let scope = this.filterScopeTarget.value
    if (scope == 'classification') {
      this.filterClassificationTarget.classList.remove("d-none");
    } else {
      this.filterClassificationTarget.classList.add("d-none");
    }
  }

  generate(event) {
    event.preventDefault()
    if (this.includedCount == 0) { return false }

    const includeSelect = this.includedOptionsTarget
    for (var i = 0; i < includeSelect.options.length; i++) {
      includeSelect.options[i].selected = true;
    }
    let myForm = this.eligibleFormTarget
    let submitButton = event.target;

    if (myForm.requestSubmit) {
      if (submitButton) {
        myForm.requestSubmit(submitButton);
      } else {
        myForm.requestSubmit();
      }
    } else {
      myForm.submit();
    }
  }

  moveToExcluded(event) {
    event.preventDefault()
    let count = this.includedCount
    const includeSelect = this.includedOptionsTarget
    let options = includeSelect.selectedOptions
    Array.from(options).forEach((item) => {
      count -= 1
      item.removeAttribute('selected')
      this.appendToExcluded(item)
      includeSelect.remove(item)
      this.includedCountTarget.textContent = count
    })
  }

  moveToIncluded(event) {
    event.preventDefault()
    let count = this.excludedCount
    const excludeSelect = this.excludedOptionsTarget
    let options = excludeSelect.selectedOptions
    Array.from(options).forEach((item) => {
      count -= 1
      item.removeAttribute('selected')
      this.appendToIncluded(item)
      excludeSelect.remove(item)
      this.excludedCountTarget.textContent = count
    })
  }

  appendToExcluded(item) {
    let count = this.excludedCount + 1
    this.excludedOptionsTarget.appendChild(item)
    this.excludedCountTarget.textContent = count
  }

  appendToIncluded(item) {
    let count = this.includedCount + 1
    this.includedOptionsTarget.appendChild(item)
    this.includedCountTarget.textContent = count
  }

  get includedCount() {
    return parseInt(this.includedCountTarget.textContent)
  }

  get excludedCount() {
    return parseInt(this.excludedCountTarget.textContent)
  }
}